import React from 'react';
import Slider from 'react-slick';

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  className: 'slider--timeline',
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Timeline() {
  const timelineData = [
    {
      year: '2009',
      text: 'Launched first Certified Watchmaking Course 3000 hours',
    },
    {
      year: '2010',
      text: 'WOSTEP Encasing Technician Courses 800 hours with 7 graduates',
    },
    {
      year: '2014',
      text: 'Expanded to two classes from 12 to 16 students',
    },
    {
      year: '2021',
      text: '7th WOSTEP Certified Watchmaking Courses 3,000 hours with 49 graduates',
    },
    {
      year: '2022',
      text: 'Introduction WOSTEP 1800 hr Customer Service Watchmaker Course',
    },
    {
      year: '2024',
      text: 'WOSTEP Certified Customer Service Watchmaker Course, 1800 hours with 26+ Graduates',
    },
  ];
  return (
    <div className="timeline_container bg-[#e5e7eb78] bg-cover">
      <div className="w-11/12 mx-auto relative">
        <Slider {...settings}>
          {timelineData.map((item, idx) => {
            return (
              <div
                className="!flex items-center"
                key={`timeline_${idx}`}
              >
                <div className="p-[2%] text-4xl leading-[100px] m-[10px] relative text-center">
                  <p className="leading-4 text-sm py-[20px] px-[40px] text-black font-medium rounded-sm">
                    <span className="text-4xl font-semibold">
                      {item.year}
                    </span>
                    <br /> <br /> {item.text}
                  </p>
                </div>
                <span
                  className={`border block border-slate-300 h-[30px] ${
                    idx === 2 || idx === 5 // every 3rd one, hide at xl
                      ? 'xl:hidden'
                      : idx % 2 !== 0 // every 1st one, hide until xl
                      ? 'hidden xl:block'
                      : 'hidden sm:block' // else, show at sm
                  }`}
                ></span>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Timeline;
