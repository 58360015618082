import React from 'react';
import TeamMember from './teamMember';
import johnSokol from '../assets/john-sokol.png';
import robFlood from '../assets/robert-flood.png';
import russPeddy from '../assets/russ-peddy.png';
import sarahAllen from '../assets/sarah-allen.png';
import brianWebster from '../assets/brian-webster.png';
import michaelMichaels from '../assets/michael-michaels.png';

function Team({ setModal }) {
  const data = [
    {
      name: 'John Sokol',
      role: 'PROGRAM DIRECTOR',
      image: johnSokol,
      bio: 'John began his 50-year career in watchmaking at a local jewelry boutique in Staten Island, New York, and over the last twenty years has worked with prestigious brands like Cartier, Panerai, and Piaget. For 10 years, John has led the NAIOSW watchmaking school with unwavering dedication and a profound passion for cultivating the talent of the next generation of watchmakers.',
      id: 'sokol',
    },
    {
      name: 'Robert Flood',
      role: 'LEAD INSTRUCTOR',
      image: robFlood,
      bio: 'Robert brings to the Institute 20+ years of experience as a certified watchmaking instructor. Since 2018, he has served as an instrumental part of the NAIOSW, as the Lead Instructor, overseeing the training programs internally and externally.',
      id: 'flood',
    },
    {
      name: 'Russ Peddy',
      role: 'INSTRUCTOR',
      image: russPeddy,
      bio: 'Russ has been an instructor since 2010 and brings a wealth of specialized educational training and real-life experience to the Institute’s faculty. He studied horology at Paris Junior College and at WOSTEP’s facilities in Neuchâtel, Switzerland. Russ has more than 30 years of experience in the watch repair industry, including roles as a technical supervisor, technical trainer, and technical training manager.',
      id: 'peddy',
    },
    {
      name: 'Sarah Allen',
      role: 'INSTRUCTOR',
      image: sarahAllen,
      bio: 'Sarah joined NAIOSW with 21 years of watchmaking experience. She graduated from the Irish Swiss Institute of Horology in Dublin, Ireland in 2001. Her watchmaking career began in London, working for Jaeger-LeCoultre and IWC. Throughout the course of Sarah’s career, she has worked as a watchmaker for several prestigious brands, served as Technical Lead for Jaeger-LeCoultre, facilitated external training for several watch brands and is now a Watchmaking Instructor.',
      id: 'allen',
    },
    {
      name: 'Brian Webster',
      role: 'INSTRUCTOR',
      image: brianWebster,
      bio: 'Brian has more than 20 years of experience as a high-level watchmaker, and has worked in retail, service centers, and now in education. Among many other certifications and multiple brand trainings, he spent six months in Switzerland studying and training to further his understanding and expertise of this profession.',
      id: 'webster',
    },
    {
      name: 'Michael Michaels',
      role: 'INSTRUCTOR',
      image: michaelMichaels,
      bio: 'Michael began his professional career working as an FAA Certified Corporate Aircraft Mechanic before following what has become his true passion; watchmaking. He began his watchmaker career 25 years ago, receiving his first U.S. Certification in 2010, and since that time has worked with many top manufactures including Omega, Hublot, L.U.C./Chopard, Jaeger-LeCoultre, Panerai, IWC, and Cartier.',
      id: 'michaels',
    },
  ];
  return (
    <div className="team text-center">
      <h2 className="font-bold mb-6 max-w-[750px] mx-auto">
        Meet the Team
      </h2>
      <p className="max-w-[750px] mx-auto">
        Passion. Dedication. Expertise.
      </p>
      <br />
      <p className="max-w-[750px] mx-auto">
        Our dedicated and passionate team of watchmaking instructors
        have more than 175 years of combined industry experience and
        are committed to nurturing the next generation of skilled
        watchmakers.
      </p>
      <div className="flex justify-center flex-wrap gap-8 mx-auto mt-[60px] max-w-[950px] md:max-w-full mb-0 md:mb-[40px]">
        {data.map((obj) => {
          return (
            <TeamMember
              key={`team-member_${obj.id}`}
              {...obj}
              setModal={setModal}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Team;
