import CTA from './CTA';
import { motion } from 'framer-motion';

function BannerHome({ reference }) {
  return (
    <div
      className="layout pt-[300px] pb-[100px] relative bg-black overflow-hidden min-h-[500px] bg-no-repeat"
      ref={reference}
    >
      <motion.div
        className="relative z-10 text-left pr-[100px] ml-20 p-0"
        initial={{
          opacity: 0,
          x: -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 1,
          },
        }}
        viewport={{ once: true }}
      >
        <h1 className="font-bold mb-8 text-4xl lg:text-5xl tracking-wider text-white">
          North American Institute
          <br /> of Swiss Watchmaking
        </h1>

        <p className="text-white max-w-[700px] my-0 ml-0 mb-8">
          Learn the Artistry of Horology and Earn an Education Like No
          Other
        </p>
        <CTA linkTo="/apply" text="Apply Now" color="white" />
      </motion.div>
      <div className="banner-img"></div>
    </div>
  );
}

export default BannerHome;
