import React from 'react';
import wostep from '../assets/wostep-logo.png';

function Footer() {
  return (
    <div className="footer bg-[#f9fafc] lg:flex-row flex-col flex justify-between items-center flex-wrap p-[60px]">
      <div className="">
        <p className="font-bold mb-8 text-black tracking-[4px] !w-full text-center lg:text-left block lg:inline-block">
          NAIOSW
        </p>
        <ul className="text-sm text-black list-style-none">
          <li className="mb-4 last-of-type:mb-0 list-none">
            <p>EMAIL</p>
            <span>
              <a href="mailto:info.na@iosw.com?subject=NAIOSW Enquiry">
                info.na@iosw.com
              </a>
            </span>
          </li>
          <li>
            <a
              href="https://www.richemont.com/talent/view-our-jobs/"
              target="_blank"
              rel="noreferrer"
            >
              <p>CAREERS</p>
              <span className="underline">Go to careers</span>
            </a>
          </li>
        </ul>
      </div>
      <a
        href="https://www.wostep.ch/en"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="mt-10 w-[180px] md:w-[220px]"
          src={wostep}
          alt="WOSTEP logo"
        />
      </a>
      <div className="w-[230px] text-center mt-8 lg:mt-0 lg:text-right">
        <a href="/privacy-policy" className="mt-6 underline">
          Privacy Policy
        </a>
        <a href="/terms-of-use" className="mt-6 underline">
          Terms of Use
        </a>
      </div>
    </div>
  );
}

export default Footer;
