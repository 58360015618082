import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const useAnalytics = (title) => {
  ReactGA.initialize('G-BJQZ5PPGPT');
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      title: title,
    });
    // eslint-disable-next-line
  }, []);
};

export default useAnalytics;
