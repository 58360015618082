import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import './styles/index.css';
import Home from './pages/home';
import About from './pages/about';
import Scholarships from './pages/scholarships';
import Programs from './pages/programs';
import Contact from './pages/contact';
import App from './layouts/App';
import Privacy from './pages/privacy';
import Terms from './pages/terms';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/scholarships',
        element: <Scholarships />,
      },
      {
        path: '/programs',
        element: <Programs />,
      },
      {
        path: '/apply',
        element: <Contact />,
      },
      { path: '/privacy-policy', element: <Privacy /> },
      { path: '/terms-of-use', element: <Terms /> },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />
);
