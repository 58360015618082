import React from 'react';
import CTA from './CTA';

function Scholarship({ name, description, link, id }) {
  return (
    <div className="block bg-[#f9fafc]">
      <div
        className={`bg-cover bg-no-repeat p-4 border-box h-[200px] text-center scholarship_banner--${id} flex items-center justify-center`}
      >
        <h2 className="font-bold text-[20px] text-white">{name}</h2>
      </div>
      <p className="p-8 text-sm">{description}</p>
      <div className="w-full text-center mb-4 flex justify-center">
        <CTA
          linkTo={link}
          external={true}
          text="Learn More"
          color="black"
          ariaLabel={`Learn More about the ${name}`}
        />
      </div>
    </div>
  );
}

export default Scholarship;
