import Banner from '../components/banner';
import Scholarship from '../components/scholarship';
import useAnalytics from '../hooks/useAnalytics';
import { Helmet } from 'react-helmet';

function Scholarships() {
  useAnalytics('Scholarships');

  const scholarships = [
    {
      name: 'Charles Sauter Scholarship',
      id: 'charles_sauter',
      description:
        'Any student who has been accepted or is currently studying at a full-time watchmaking school in the U.S. is eligible for the Charles Sauter Scholarship. Prospective students may also apply, with the understanding that the scholarship is contingent on their enrollment in a full-time watchmaking school. The scholarship is awarded every April. Awards up to $5,000 are available.',
      link: 'https://hs-ny.org/charles-sauter-scholarship',
    },
    {
      name: 'Grace Fryer Scholarship',
      id: 'grace_fryer',
      description:
        'Women who have been accepted or are currently studying at a full-time watchmaking school in the U.S. are eligible for the Grace Fryer Scholarship. Prospective students may also apply, with the understanding that the scholarship is contingent on their enrollment in a full-time watchmaking school. The scholarship is awarded every April. Awards up to $5,000 are available.',
      link: 'https://hs-ny.org/grace-fryer-scholarship',
    },
    {
      name: 'Henry B. Fried Scholarship',
      id: 'henry_fried',
      description:
        'Students who have been accepted or are currently studying at a full-time watchmaking school in the U.S. are eligible for the Henry B. Fried Scholarship. Prospective students may also apply, with the understanding that the scholarship is contingent on their enrollment in a full-time watchmaking school. The scholarship is awarded every April. Awards up to $5,000 are available.',
      link: 'https://hs-ny.org/scholarship',
    },
    {
      name: 'Benjamin Banneker Scholarship',
      id: 'ben_banneker',
      description:
        'Black students who have been accepted, or are currently studying at a full-time watchmaking school in the U.S. are eligible for the Benjamin Banneker Scholarship. Prospective students may also apply, with the understanding that the scholarship is contingent on their enrollment in a full-time watchmaking school. The scholarship is awarded every April. Awards up to $5,000 are available.',
      link: 'https://hs-ny.org/benjamin-banneker-scholarship',
    },
    {
      name: 'Charles London Scholarship',
      id: 'charles_london',
      description:
        'Students who have been accepted or are currently studying at a full-time watchmaking school in the U.S. are eligible for the Charles London Scholarship. Prospective students may also apply, with the understanding that the scholarship is contingent on their enrollment in a full-time watchmaking school. The scholarship is awarded every April. Awards up to $5,000 are available.',
      link: 'https://hs-ny.org/charles-london-scholarship',
    },
    {
      name: 'Oscar Waldan Scholarship',
      id: 'oscar_waldan',
      description:
        'Jewish students who have been accepted or are currently studying at a full-time watchmaking school in the U.S. are eligible for the Oscar Waldan Scholarship. Prospective students may also apply, with the understanding that the scholarship is contingent on their enrollment at a full-time watchmaking school. The scholarship is awarded every April. Awards up to $5,000 are available.',
      link: 'https://hs-ny.org/oscar-waldan-scholarship',
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Scholarships - North American Institute of Swiss Watchmaking
        </title>
        <meta
          name="description"
          content="Scholarships offered by and associated with the NAIOSW to help with the cost of education"
        />
        <meta
          name="keywords"
          content="scholarship, education, fund, watchmaker, watchmaking, skills, students, craftsmanship, learn, horology"
        />
      </Helmet>

      <div>
        <Banner title="Scholarships" />
        <div className="layout section bg-white text-center">
          <h2 className="font-bold mx-auto max-w-[700px] mb-6">
            Scholarship Opportunities
          </h2>
          <p className="mx-auto max-w-[700px]">
            We understand that the cost of education is sometimes a
            difficult barrier in the pursuit of a dream career. We
            offer several scholarship options for eligible students,
            including our own NAIOSW scholarship and scholarships
            offered through our association partners.
          </p>
          <br />
          <p>
            Learn more about our scholarship opportunities and start
            your career in watchmaking today!
          </p>
        </div>
        <div className="scholarships_container">
          {scholarships.map((obj) => (
            <Scholarship key={obj.id} {...obj} />
          ))}
        </div>
      </div>
    </>
  );
}

export default Scholarships;
