import { useEffect } from 'react';

const useSlickAccessibility = () => {
  useEffect(() => {
    const slickArrows = document.querySelectorAll('.slick-arrow');
    if (slickArrows.length >= 1) {
      slickArrows.forEach((arrow) => {
        const parentClasses = [...arrow.parentElement.classList];
        let identifierClass = parentClasses.filter((curr) => {
          return curr.includes('slider--');
        })[0];
        const identifier = identifierClass.split('--')[1];
        if (arrow.classList.contains('slick-next')) {
          arrow.ariaLabel = `Next ${identifier}`;
        } else {
          arrow.ariaLabel = `Previous ${identifier}`;
        }
        arrow.textContent = '';
      });
    }

    return () => {};
  }, []);
};

export default useSlickAccessibility;
