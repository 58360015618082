import React from 'react';

function Hamburger({ showMobile }) {
  return (
    <div className={`hamburger ${showMobile && 'hamburger--close'}`}>
      <span className="top"></span>
      <span className="middle"></span>
      <span className="bottom"></span>
    </div>
  );
}

export default Hamburger;
