import React from 'react';
import { Field } from 'formik';

function FormControl({ label, name, ...props }) {
  return (
    <div className="form-control">
      <label
        htmlFor={name}
        className={`flex ${
          props.as === 'textarea' ? 'items-start' : 'items-center'
        }`}
      >
        <span
          className={`text-red-600 mr-2 text-sm font-medium ${
            props.as !== 'textarea' ? 'mt-1' : ''
          }`}
        >
          *
        </span>
        {label}
      </label>
      <Field
        type="text"
        name={name}
        as={props.as}
        options={props.options}
        id={name}
      />
      {props.touched[name] && props.errors[name] ? (
        <p className="form-error">{props.errors[name]}</p>
      ) : null}
    </div>
  );
}

export default FormControl;
