import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import jack from '../assets/jack.png';
import aleksandra from '../assets/aleksandra.png';
import chris from '../assets/testimonial.png';
import nate from '../assets/nate.png';

function Testimonials() {
  var settings = {
    dots: false,
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    className: 'slider--testimonials',
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const data = [
    {
      image: aleksandra,
      name: 'Aleksandra Halic, Watchmaker (Class of 2019)',
      text: `"[I’m so] happy and grateful that I received the opportunity to attend the North American Institute of Swiss Watchmaking. This prestigious Institute is challenging and exciting, but most importantly, rewarding. I truly enjoyed every minute of my experience, even when things didn’t always go as easy as I expected or wanted them to. This is how I learned to be patient. The instructors are truly incredible; eager and always willing to share their experience and knowledge with students."`,
    },
    {
      image: chris,
      name: 'Chris Bridges, Watchmaker (Class of 2019)',
      text: `"[Attending] NAIOSW was one of the best professional decisions I’ve ever made. I had opportunities to dive deeply into the art and science of watchmaking, while challenging myself academically. This school gave me the knowledge and tools to do things I thought would be all but impossible at the outset. Simply put, NAIOSW is the standard by which all other programs are defined."`,
    },
    {
      image: jack,
      name: 'Jack Evans, Watchmaker (Class of 2021)',
      text: `“A watchmaking career is not a common career but a very rewarding career. The North American Institute of Swiss Watchmaking gave me all the knowledge, skills, and passion I needed to truly excel in my watchmaking journey. Plus, I get to work on watches all day!”`,
    },
    {
      image: nate,
      name: 'Nate Lowe, Watchmaker (Class of 2023)',
      text: `"I really knew nothing about watches other than telling time before this program. The curriculum is designed to bring someone from zero knowledge of watches and give them a firm foundational horological education on which to build a professional watchmaking career."`,
    },
  ];
  return (
    <div className="layout section !pb-[90px] !md:pb-[120px] bg-white">
      <h2 className="font-bold text-center mx-auto">
        Our Students Say
      </h2>
      <Slider {...settings}>
        {data.map((content, idx) => {
          return (
            <div
              className="relative mt-[30px] pt-[100px]"
              key={`testimonial_${idx}`}
            >
              <div
                key={`testimonial_${idx}`}
                className="text-center overflow-visible"
              >
                <img
                  src={content.image}
                  alt={content.name}
                  className="w-[150px] left-1/2 absolute translate-x-[-50%] top-0"
                />
                <div className="bg-[#f9fafc] p-8 pt-16 my-0 mx-[20px] rounded-sm">
                  <p>{content.text}</p>
                  <br />
                  <p>{content.name}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default Testimonials;
