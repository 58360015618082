import React from 'react';
import CTA from './CTA';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import alsLogo from '../assets/logo_als.svg';
import carLogo from '../assets/logo_car.svg';
import dubuisLogo from '../assets/logo_dubuis.svg';
import panLogo from '../assets/logo_pan.svg';
import bemLogo from '../assets/logo_bem.svg';
import iwcLogo from '../assets/logo_iwc.svg';
import vcaLogo from '../assets/logo_vca.svg';
import mtbLogo from '../assets/logo_mtb.svg';
import vacLogo from '../assets/logo_vac.svg';
import dunhillLogo from '../assets/logo_dunhill.svg';
import jlcLogo from '../assets/logo_jlc.svg';
import piagetLogo from '../assets/logo_piaget.svg';

function Careers() {
  var settings = {
    dots: false,
    infinite: true,
    adaptiveHeight: false,
    speed: 500,
    autoplay: true,
    slidesToShow: 6,
    autoplaySpeed: 5000,
    centerMode: false,
    className: 'slider variable-width slider--careers',
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const logos = [
    {
      image: vacLogo,
      alt: 'Vacheron Constantin',
      link: 'https://www.vacheron-constantin.com/us/en/home.html',
    },
    {
      image: mtbLogo,
      alt: 'Montblanc',
      link: 'https://www.montblanc.com/en-us',
    },
    {
      image: vcaLogo,
      alt: 'Van Cleef & Arpels',
      link: 'https://www.vancleefarpels.com/us/en/home.html',
    },
    {
      image: panLogo,
      alt: 'Panerai',
      link: 'https://www.panerai.com/us/en/home.html',
    },
    {
      image: dunhillLogo,
      alt: 'Dunhill',
      link: 'https://www.dunhill.com/sa',
    },
    {
      image: dubuisLogo,
      alt: 'Roger Dubuis',
      link: 'https://www.rogerdubuis.com/',
    },
    {
      image: bemLogo,
      alt: 'Baume & Mercier',
      link: 'https://www.baume-et-mercier.com/us/en/home.html',
    },
    {
      image: carLogo,
      alt: 'Cartier',
      link: 'https://www.cartier.com/en-us/home',
    },
    {
      image: iwcLogo,
      alt: 'IWC',
      link: 'https://www.iwc.com/us/en/home.html',
    },
    {
      image: alsLogo,
      alt: 'A.Lange & Sohne',
      link: 'https://www.alange-soehne.com/us-en',
    },
    {
      image: piagetLogo,
      alt: 'Piaget',
      link: 'https://www.piaget.com/',
    },
    {
      image: jlcLogo,
      alt: 'Jaeger LeCoultre',
      link: 'https://www.jaeger-lecoultre.com/us-en',
    },
  ];

  return (
    <motion.div
      className="careers p-[120px] bg-black text-center"
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
        transition: {
          duration: 1,
        },
      }}
      viewport={{ once: true }}
    >
      <h2 className="font-bold text-white max-w-[680px] mx-auto my-0 mb-6">
        Careers
      </h2>
      <p className="text-white max-w-[680px] mx-auto my-0 mb-8">
        We are looking for talented individuals who are curious and
        passionate about horology to become stewards of the fine art
        of Swiss watchmaking. 
      </p>

      <div className="logos-container">
        <Slider {...settings}>
          {logos.map(({ alt, image, link }, idx) => {
            return (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                key={`logo_${idx}`}
              >
                <img className="logo" alt={alt} src={image} />
              </a>
            );
          })}
        </Slider>
      </div>
      <div className="w-full flex justify-center">
        <CTA
          linkTo="https://www.richemont.com/talent/view-our-jobs/"
          text="Explore a career at one of our maisons"
          color="white"
          classes="w-max"
          external={true}
        />
      </div>
    </motion.div>
  );
}

export default Careers;
