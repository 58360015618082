import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import CTA from './CTA';
import Hamburger from './hamburger';
import Logo from '../assets/logo.svg';

function Navbar() {
  const [showMobile, setShowMobile] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 700) {
        setShowMobile(false);
      }
    });
    return () => {
      window.removeEventListener('resize', null);
    };
  }, []);

  const clickLink = () => {
    window.scrollTo(0, 0);
    setShowMobile(false);
  };

  return (
    <nav
      className="navbar fixed bg-white p-8 pb-0 !w-screen !max-w-none"
      style={{ top: showMobile ? '0px' : '-190px' }}
    >
      <div>
        <NavLink
          to="/about"
          className="ml-4 mb-2 tracking-wider uppercase"
          onClick={clickLink}
        >
          About
        </NavLink>
        <NavLink
          to="/programs"
          className="ml-4 mb-2 tracking-wider uppercase"
          onClick={clickLink}
        >
          Programs
        </NavLink>
        <NavLink
          to="/scholarships"
          className="ml-4 mb-2 tracking-wider uppercase"
          onClick={clickLink}
        >
          Scholarships
        </NavLink>
        <div
          className="ml-4 mb-2 mt-4 tracking-wider uppercase"
          onClick={clickLink}
        >
          <CTA
            linkTo="/apply"
            text="Apply"
            color="black-fill"
          />
        </div>
      </div>
      <div className="flex py-4 bg-white">
        <div className="flex-1">
          <NavLink to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={Logo} alt="NAIOSW logo" className="h-[60px]" />
          </NavLink>
        </div>
        <div className="md:flex items-center gap-8 hidden">
          <NavLink
            to="/about"
            className="tracking-wider uppercase text-sm"
            onClick={() => window.scrollTo(0, 0)}
          >
            About
          </NavLink>
          <NavLink
            to="/programs"
            className="tracking-wider uppercase text-sm"
            onClick={() => window.scrollTo(0, 0)}
          >
            Programs
          </NavLink>
          <NavLink
            to="/scholarships"
            className="tracking-wider uppercase text-sm"
            onClick={() => window.scrollTo(0, 0)}
          >
            Scholarships
          </NavLink>
          <CTA
            linkTo="/apply"
            text="APPLY"
            color="black-fill"
            onClick={() => window.scrollTo(0, 0)}
          />
        </div>
        <div
          className="block md:hidden mt-4"
          onClick={() => setShowMobile((prev) => !prev)}
        >
          <Hamburger showMobile={showMobile} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
