import { Outlet } from 'react-router-dom';

import ScrollToTop from '../components/scrollToTop';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

function App() {
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div>
        <Outlet />
      </div>
      {/* <div className="home-accent"></div> */}
      <Footer />
    </>
  );
}

export default App;
