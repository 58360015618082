import React from 'react';
import { motion } from 'framer-motion';

function Banner({ title, subtitle, classes }) {
  return (
    <div className="layout pt-[160px] pb-[60px] relative bg-black overflow-hidden">
      <motion.div
        className="banner_text my-[20px] mx-auto max-w-[1000px] text-center p-[40px] z-10 relative"
        initial={{
          opacity: 0,
          x: -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 1,
          },
        }}
        viewport={{ once: true }}
      >
        <h1 className='font-bold mb-8 text-4xl lg:text-5xl tracking-wider text-white'>{title}</h1>
        <p className='text-white max-w-[700px] my-0 mx-auto mb-8'>{subtitle}</p>
      </motion.div>
      <div className="banner-img"></div>
    </div>
  );
}

export default Banner;
