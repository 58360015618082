import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../components/banner';
import Form from '../components/form';

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Apply - North American Institute of Swiss Watchmaking
        </title>
        <meta
          name="description"
          content="Form to apply to the North American Institute of Swiss Watchmaking (NAIOSW)"
        />
        <meta
          name="keywords"
          content="application, apply, form, school, watchmaking, swiss, apply, craftsmanship, learn, horology"
        />
      </Helmet>
      <div>
        <Banner
          title="Apply Now"
          subtitle="At the NAIOSW, we offer a unique opportunity for everyone to pursue a career in the prestigious field of watchmaking. Regardless of whether you have a technical background, we welcome individuals with passion, dedication, patience, curiosity, and a love of luxury watches. Our program is designed to unlock your potential, grow your skills, and prepare you for success in the world of watchmaking."
        />
        <Form />
      </div>
    </>
  );
}

export default Contact;
