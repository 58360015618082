import React from 'react';
import { motion } from 'framer-motion';

function ContentSection({
  backgroundColor,
  header,
  text,
  orientation,
  imgId,
  reference,
}) {
  return (
    <div
      className={`layout flex lg:flex-row flex-col bg-${backgroundColor} ${
        orientation === 'right' ? 'lg:flex-row flex-col-reverse' : ''
      }`}
      ref={reference}
    >
      {orientation === 'right' && (
        <div
          className={`content-section_img w-full h-[300px] lg:h-auto lg:w-1/2 bg-cover  content-section_img_${imgId}`}
        ></div>
      )}
      <div className="py-[140px] px-[80px] box-border lg:w-1/2 w-full">
        <motion.h2
          className={`font-bold mb-6 lg:max-w-[550px] max-w-full ${
            orientation === 'right' ? 'text-white' : ''
          }`}
          initial={{
            opacity: 0,
            x: orientation === 'right' ? 50 : -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          {header}
        </motion.h2>
        <p
          className={`lg:max-w-[550px] max-w-full ${
            orientation === 'right' ? 'text-white opacity-90' : ''
          }`}
        >
          {text.map(({ copy }, idx) => {
            return (
              <motion.span
                initial={{
                  opacity: 0,
                  y: -50,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
                key={`copy_${idx}`}
              >
                {copy}
              </motion.span>
            );
          })}
        </p>
      </div>
      {orientation === 'left' && (
        <div
          className={`content-section_img w-full h-[300px] lg:h-auto lg:w-1/2 bg-cover  content-section_img_${imgId}`}
        ></div>
      )}
    </div>
  );
}

export default ContentSection;
