import { useEffect } from 'react';
import {
  motion,
  useMotionValue,
  useTransform,
  animate,
} from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Stat({ number, description }) {
  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      animate(count, Number(number), {
        duration: 2,
      });
    }
  });

  return (
    <div className="w-[150px]">
      <motion.p ref={ref} viewport={{ once: true }} className="text-[36px] font-normal">
        {rounded}
      </motion.p>
      <span className="w-[30px] inline-block border border-t-gray-500"></span>
      <p className="text-xs leading-5">{description}</p>
    </div>
  );
}

export default Stat;
