import React from 'react';
import { motion } from 'framer-motion';

function Arrow({ width }, ...props) {
  return (
    <motion.svg
      whileHover={{ scale: 1.1 }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="auto"
      fill="none"
      viewBox="0 0 697 406"
      {...props}
    >
      <title id="scroll-arrow-title">Scroll down arow</title>
      <desc>
        An arrow pointing downwards indicating to scroll down
      </desc>
      <path
        stroke="#000"
        strokeWidth="80"
        d="m377.716 377.284-349-349m299 343.432 341-341"
      />
    </motion.svg>
  );
}

export default Arrow;
