import Banner from '../components/banner';
import Careers from '../components/careers';
import Program from '../components/program';
import Testimonials from '../components/testimonials';
import useAnalytics from '../hooks/useAnalytics';
import CTA from '../components/CTA';
import useSlickAccessibility from '../hooks/useSlickAccessibility';
import { Helmet } from 'react-helmet';

function Programs() {
  useAnalytics('Programs');
  useSlickAccessibility();

  return (
    <>
      <Helmet>
        <title>
          Our Programs - North American Institute of Swiss Watchmaking
        </title>
        <meta
          name="description"
          content="Programs at the North American School of Swiss Watchmaking, including encasing technicians, customer service repairs, and internships"
        />
        <meta
          name="keywords"
          content="encasing, technician, watchmaker, skills, repairs, internship, WOSTEP, school, watchmaking, swiss, apply, craftsmanship, learn, horology"
        />
      </Helmet>
      <div>
        <Banner
          title="Our Programs"
          subtitle="NAIOSW offers three different WOSTEP-certified programs designed to educate and inspire students with a curiosity and passion for horology, equipping them with the skills and knowledge necessary for a successful and rewarding career in the watchmaking industry."
        />
        <div className="layout bg-white flex flex-col md:flex-row">
          <Program
            id="1"
            title="Customer Service Watchmaker"
            length="1 YEAR PROGRAM"
            description="The Customer Service Watchmaker Program teaches students the necessary skills in watchmaking service operations, through both practical and theoretical training."
            focus={{
              description: 'This program focuses on: ',
              bullets: [
                'Fundamentals of Micromechanics',
                'Watch Encasing',
                {
                  'Watch Servicing': [
                    'Quartz, Mechanical, Chronograph and Automatic Calibers',
                  ],
                },
                'Repair and Adjustment Procedure',
              ],
            }}
            requirements={[
              'Authorized to work in the United States',
              'High School Degree or GED',
              'No watchmaking experience requirement',
            ]}
            link="/apply"
          />
          <Program
            id="2"
            title="Encasing Technician"
            length="4 MONTH PROGRAM"
            description="The Encasing Technician Course teaches students the necessary skills in micromechanics and basic watch encasing service operations, through both practical and theoretical training, to prepare them for a successful career."
            focus={{
              description: 'This course focuses on:',
              bullets: [
                'Fundamentals of Micromechanics',
                'Watch Encasing',
                'Repair and Adjustment Procedures',
                'Movement Exchange',
              ],
            }}
            requirements={[
              'Authorized to work in the United States',
              'High School Degree or GED',
              'No watchmaking experience requirement',
            ]}
            link="/apply"
          />
        </div>
        <div className="layout flex-col md:flex-row flex bg-black">
          <div className="md:w-1/2 w-full border-box section">
            <h2 className="font-bold text-2xl text-white mb-6">
              High School Summer Internship
            </h2>
            <p className="text-white">
              With our internship, students have an opportunity to
               work in the Technical Workshop at Richemont for a
              period of six weeks, supporting different functions of
              the Repair Operation. 
            </p>
            <br />
            <p className="mb-6 text-white">
              A one-week training will take place regarding
              Micro-Mechanics and how tools and products are handled. 
              This will be to prepare the individual for what they
              will be participating in the remaining 5 weeks!
            </p>
            <div className="w-full text-center mt-[30px]">
              <CTA linkTo="/apply" text="Apply" color="white" />
            </div>
          </div>
          <div className="internship-img"></div>
        </div>
        <Testimonials />
        <Careers />
      </div>
    </>
  );
}

export default Programs;
