import React, { useEffect, useRef } from 'react';
import BannerHome from '../components/bannerHome';
import ContentSection from '../components/contentSection';
import Careers from '../components/careers';
import Stats from '../components/stats';
import useAnalytics from '../hooks/useAnalytics';
import titleAnimation from '../assets/titleAnimation.mp4';
import Arrow from '../components/arrow';
import { useState } from 'react';
import Loader from '../components/loader';
import emptyCaptions from '../assets/vtt/empty.vtt';
import useSlickAccessibility from '../hooks/useSlickAccessibility';
import { Helmet } from 'react-helmet';

function Home() {
  useAnalytics('Home');
  useSlickAccessibility();

  const [showTitle, setShowTitle] = useState(false);
  const main = useRef(null);
  const title = useRef(null);
  const video = useRef(null);

  useEffect(() => {
    const hasVisited = localStorage.getItem('visited');
    // if first visit
    if (!hasVisited) {
      localStorage.setItem('visited', true);
      setShowTitle(true);
    } else {
      setShowTitle(false);
    }

    if (video.current) {
      video.current.muted = true;
      video.current.defaultMuted = true;
    }
  }, []);

  const executeScroll = () =>
    main.current.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });

  return (
    <>
      <Helmet>
        <title>
          Home - North American Institute of Swiss Watchmaking
        </title>
        <meta
          name="description"
          content="North American Institute of Swiss Watchmaking (NAIOSW) website, information, and application"
        />
        <meta
          name="keywords"
          content="watchmaking, school, swiss, apply, craftsmanship, learn, horology, WOSTEP, students, education"
        />
      </Helmet>

      <div className="home-container">
        <span ref={title}></span>
        <div
          className="video-container"
          style={{ display: showTitle ? 'flex' : 'none' }}
        >
          <div
            className="video-loader"
            role="img"
            aria-labelledby="loading-animation-title"
          >
            <Loader />
          </div>
          <video
            width="auto"
            autoPlay
            muted={true}
            loop
            ref={video}
            aria-labelledby="title-animation-description"
          >
            <source src={titleAnimation} type="video/mp4" />
            <track
              src={emptyCaptions}
              kind="captions"
              srcLang="en"
              label="English"
            />
            Your browser does not support the video tag.
          </video>
          <span
            id="title-animation-description"
            className="opacity-0"
          >
            Title animation video, spelling NAIOSW, automatically
            plays and loops, with no sound.
          </span>
          <p className="text-[22px]">THE TIME IS NOW</p>
          <div
            className="scroll-arrow-container"
            onClick={executeScroll}
            role="button"
            aria-labelledby="scroll-arrow-title"
          >
            <Arrow width="70px" />
          </div>
        </div>
        <BannerHome reference={main} />

        <ContentSection
          backgroundColor="white"
          imgId="1"
          orientation="left"
          header="Traditional Techniques with Modern Technology"
          text={[
            {
              copy: 'Our world-class instructors at the North American Institute of Swiss Watchmaking (NAIOSW) are committed to preserving the time-honored craftsmanship of Swiss watchmaking while providing students with a cutting-edge education in horology.',
              tooltip: '',
            },
          ]}
        />

        <ContentSection
          backgroundColor="black"
          imgId="2"
          orientation="right"
          header="Why Choose Us?"
          text={[
            {
              copy: 'The NAIOSW is proud of its rich history; providing the best education available in Swiss watchmaking, with guidance and instruction provided by expert crafters and watchmakers. ',
              tooltip: '',
            },

            {
              copy: ' Our graduates',
              tooltip:
                'Starting Salary around $62K, with competitive benefits.',
            },

            {
              copy: ' go on to become leading watchmakers who keep the luxury watch industry ticking.',
              tooltip: '',
            },
          ]}
          cta={{ linkTo: '/', text: 'Learn More', color: 'white' }}
        />
        <Stats />
        <Careers />
      </div>
    </>
  );
}

export default Home;
