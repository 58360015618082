import React from 'react';
import CTA from './CTA';
import wostep from '../assets/wostep-logo.png';

function Program({
  id,
  title,
  length,
  description,
  focus,
  requirements,
  link,
}) {
  return (
    <div
      className={`${
        id === '1' ? 'bg-[#f9fafc]' : 'bg-white'
      } w-full md:w-1/2 flex flex-col items-center section relative`}
    >
      {id === '1' ? (
        <img
          className="absolute top-[40px] width-[100px] h-[20px] right-[40px]"
          src={wostep}
          alt="WOSTEP logo"
        />
      ) : null}

      <div
        className={`program_content max-w-[480px] ${
          id === '1' ? 'mt-8 md:mt-0' : ''
        }`}
      >
        <h2 className="font-bold text-2xl mb-2">{title}</h2>
        <p>{length}</p>
        <p className="mt-[30px]">{description}</p>
        <br />
        <p>{focus.description}</p>
        <ul className="list-disc list-inside">
          {focus.bullets.map((bullet, idx) => {
            if (typeof bullet !== 'string') {
              const parentBullet = Object.keys(bullet)[0];
              const subBullets = Object.values(bullet)[0];
              return (
                <React.Fragment key={`bullet_${idx}`}>
                  <li>
                    {parentBullet}{' '}
                    <ul className="list-disc list-inside ml-6">
                      {subBullets.map((sub) => {
                        return (
                          <li key={`sub-bullet_${idx}`}>{sub}</li>
                        );
                      })}
                    </ul>
                  </li>
                </React.Fragment>
              );
            } else {
              return <li key={`bullet_${idx}`}>{bullet}</li>;
            }
          })}
        </ul>
        <br />
        <p>Requirements:</p>
        <ul className="list-disc list-inside">
          {requirements.map((req, idx) => (
            <li key={`req_${idx}`}>{req}</li>
          ))}
        </ul>
      </div>
      <div className="w-full text-center mt-12">
        <CTA linkTo={link} text="Apply" color="black-fill" />
      </div>
    </div>
  );
}

export default Program;
