import { useState, useEffect } from 'react';
import Banner from '../components/banner';
import Timeline from '../components/timeline';
import Team from '../components/team';
import { motion } from 'framer-motion';
import Modal from '../components/modal';
import aboutGroup from '../assets/about-group.png';
import useAnalytics from '../hooks/useAnalytics';
import useSlickAccessibility from '../hooks/useSlickAccessibility';
import { Helmet } from 'react-helmet';

function About() {
  useAnalytics('About');
  useSlickAccessibility();

  const [modal, setModal] = useState(null);

  useEffect(() => {
    const body = document.querySelector('body');

    if (modal) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }
    return () => {};
  }, [modal]);

  return (
    <>
      <Helmet>
        <title>
          About - North American Institute of Swiss Watchmaking
        </title>
        <meta
          name="description"
          content="Learn more about the North American Institute of Swiss Watchmaking, its history, and its team."
        />
        <meta
          name="keywords"
          content="watchmaking, school, swiss, apply, craftsmanship, learn, horology, legacy, tradition, story, timeline"
        />
      </Helmet>
      {modal && <Modal modalData={modal} setModal={setModal} />}
      <Banner
        title="About Us"
        subtitle="Our legacy is steeped in the artistry of Swiss watchmaking, providing a gateway to the world of elegance, craftsmanship, and tradition."
      />
      <div className="about flex flex-col xl:flex-row justify-between items-center xl:items-start bg-white gap-12 pb-[80px]">
        <div className="w-full xl:w-[55%] text-center xl:text-left">
          <h2 className="font-bold mb-6 max-w-[750px] mx-auto xl:mx-0">
            Our Story
          </h2>
          <p className="max-w-[750px] mx-auto xl:mx-0">
            Founded in 2008, the NAIOSW emerged as a pioneering
            institution dedicated to preserving and advancing the art
            of Swiss watchmaking. 
          </p>
          <br />
          <p className="max-w-[750px] mx-auto xl:mx-0">
            From its inception, the Institute has provided a unique
            educational environment, in alignment with the Watchmakers
            of Switzerland Training and Educational Program (WOSTEP),
            that quickly gained recognition for its commitment to
            excellence. Drawing upon the expertise of seasoned
            watchmakers and industry leaders, NAIOSW offers a
            curriculum that combines time-honored horological
            techniques with cutting-edge innovations to propel the
            enduring traditions of watchmaking continually forward. 
          </p>
          <br />
          <p className="max-w-[750px] mx-auto xl:mx-0">
            By collaborating with WOSTEP, NAIOSW has solidified its
            commitment to delivering a world-class watchmaking
            education. This partnership leverages a wealth of
            knowledge, experience, and industry insights and provides
            access to WOSTEP's renowned curriculum and methodologies
            to ensure that students receive training and build skills
            that are aligned with international watchmaking
            standards. 
          </p>
          <br />
          <p className="max-w-[750px] mx-auto xl:mx-0">
            The WOSTEP collaboration serves as a testament to NAIOSW's
            dedication to excellence, elevating the institute's
            standing within the global watchmaking community, and
            reinforcing its position as the pinnacle of horology for
            aspiring watchmakers in North America. 
          </p>
        </div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
          className="about_images"
        >
          <img
            src={aboutGroup}
            alt="collage of watchmaking school images"
          />
        </motion.div>
      </div>
      <Timeline />

      <Team setModal={setModal} />
    </>
  );
}

export default About;
