import React from 'react';

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 512 512"
    >
      <path
        fill="#000"
        d="m286.08 256 134.4-134.187a21.42 21.42 0 1 0-30.293-30.293L256 225.92 121.813 91.52a21.419 21.419 0 0 0-36.567 15.147 21.419 21.419 0 0 0 6.274 15.146L225.92 256 91.52 390.187a21.326 21.326 0 0 0-6.31 15.146 21.335 21.335 0 0 0 6.31 15.147 21.326 21.326 0 0 0 15.147 6.31 21.335 21.335 0 0 0 15.146-6.31L256 286.08l134.187 134.4a21.319 21.319 0 0 0 15.146 6.31 21.336 21.336 0 0 0 15.147-6.31 21.336 21.336 0 0 0 0-30.293L286.08 256Z"
      />
    </svg>
  );
}

export default CloseIcon;
