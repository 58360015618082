import React from 'react';

function TeamMember(props) {
  return (
    <div className="team-member w-auto flex flex-col items-center justify-center mb-8 cursor-pointer" onClick={() => props.setModal(props)}>
      <img src={props.image} alt={props.name} className="h-[260px] w-auto mb-2 rounded-md" />
      <p className='text-center text-sm font-medium'>{props.name}</p>
      <p className="text-xs">{props.role}</p>
    </div>
  );
}

export default TeamMember;
