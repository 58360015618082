import React from 'react';
import { Link } from 'react-router-dom';

function CTA({ linkTo, text, color, external, ariaLabel, classes }) {
  if (external) {
    return (
      <a
        href={linkTo}
        target="_blank"
        rel="noreferrer"
        className={`cta ${color && `cta--${color}`} ${classes || ''}`}
        aria-label={ariaLabel}
      >
        {text}
      </a>
    );
  }
  return (
    <Link
      to={linkTo}
      className={`cta ${color && `cta--${color}`}`}
      aria-label={ariaLabel}
    >
      {text}
    </Link>
  );
}

export default CTA;
