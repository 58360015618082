import React from 'react';
import Stat from './stat';

function Stats() {
  return (
    <div className="stats flex">
      <div className="w-auto px-[80px] py-[140px] box-border">
        <h2 className="font-bold mb-12">NAIOSW by the Numbers</h2>
        <div className="flex flex-wrap max-w-[520px] sm:gap-4 gap-8 justify-start">
          <Stat number="105" description="GRADUATES" />
          <Stat number="5" description="INSTRUCTORS" />
          <Stat number="15" description="YEARS OF EXCELLENCE" />
          <Stat
            number="97"
            description="% CERTIFICATION SUCCESS RATE"
          />
          <Stat
            number="175"
            description="YEARS OF INSTRUCTOR EXPERIENCE"
          />
        </div>
      </div>
      <div className="stats_bg bg-cover h-auto flex-1 bg-no-repeat"></div>
    </div>
  );
}

export default Stats;
