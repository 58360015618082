import React from 'react';
import { Helmet } from 'react-helmet';

function Privacy() {
  return (
    <>
      <Helmet>
        <title>
          Privacy Policy - North American Institute of Swiss Watchmaking
        </title>
        <meta
          name="description"
          content="North American Institute of Swiss Watchmaking (NAIOSW) website, information, and application"
        />
        <meta
          name="keywords"
          content="watchmaking, school, swiss, apply, craftsmanship, learn, horology, WOSTEP, students, education"
        />
      </Helmet>
      <div className="p-16 mt-[160px] max-w-[800px] mx-auto">
        <h1 className="text-3xl !leading-10 font-semibold">
          North American Institute of Swiss Watchmaking Privacy Policy
        </h1>
        <br />
        <br />
        <p>Last updated: August 2024 </p>
        <br />
        <br />
        <h2 className="text-xl mb-2 font-medium">
          About North American Institute of Swiss Watchmaking{' '}
        </h2>
        <p>
          North American Institute of Swiss Watchmaking (“NAIOSW”,
          "we", "us" and "our") has its registered offices at 15100
          Trinity Blvd Fort Worth, TX 76155. This is considered to be
          the “data controller” for the purposes of certain data
          protection laws and regulations.
        </p>
        <br />
        <br />
        <h2 className="text-xl mb-2 font-medium">
          Our Privacy Commitments
        </h2>
        <p>
          Our Privacy Policy is centred around the following three
          privacy commitments:{' '}
        </p>
        <br />
        <span>
          <b>Commitment 1: Transparency & Trust - </b>
          <i>
            Privacy is built into all of our products and services by
            design and by default. We respect the trust you place in
            us with your personal information. We will be fully
            transparent with you regarding the purposes for which we
            use your personal information and will only use it for
            those specified purposes when we have a right to do so.
            This will include, where necessary, obtaining your
            explicit consent. Any material changes to how we process
            your personal information will be notified to you.
          </i>
        </span>
        <br />
        <br />
        <span>
          <b>Commitment 2: Protecting Your Personal information - </b>
          <i>
            We commit to implementing leading data protection, privacy
            and security standards so that you feel comfortable that
            your personal information is protected – if there is an
            incident impacting your personal information, we commit to
            notifying you and/or relevant regulators in accordance
            with data breach notification requirements. Your personal
            information will be handled with the same protection when
            it is shared with third parties or when it is transferred
            internationally. We will only retain your personal
            information for as long as is necessary or for as long as
            required by law.
          </i>
        </span>
        <br />
        <br />
        <span>
          <b>Commitment 3: Respecting Your Rights - </b>
          <i>
            We will respect the choices you make in relation to your
            personal information. We will respect the legal rights you
            have in relation to accessing, erasing and updating the
            personal information that we hold about you. We will also
            respect the choices you make in relation to objecting to
            how we process your personal information and will provide
            channels for you to contact us with questions or
            complaints.
          </i>
        </span>
        <br />
        <br />
        <br />
        <h2 className="text-xl mb-2 font-medium">
          This Privacy Policy & Updates{' '}
        </h2>
        <p>
          Please take a moment to read the following policy that
          explains how we collect, use, disclose and transfer the
          personal information collected about you on this website
          (the “Platform”), when you visit our events, contact us by
          e-mail, telephone or online chat, or when you interact with
          us over social media platforms or other marketing and
          advertising channels.
        </p>
        <p>
          From time to time we may update this Privacy Policy. When we
          do, we will publish the changes on this Platform.
        </p>
        <br />
        <br />
        <h2 className="text-xl mb-2 font-medium underline">
          Commitment 1: Transparency & Trust
        </h2>
        <br />
        <h3 className="text-lg font-medium mb-2">
          Information that you provide to us or we collect about you{' '}
        </h3>
        <p>
          We collect the following personal information about you as
          detailed below:{' '}
        </p>
        <br />
        <span>
          <b>
            General personal information and application submissions:
          </b>
          <p>
            To benefit from our services or recruiting events, you
            will need to provide personal information such as your
            full name, mailing address, e-mail address, telephone
            number We may also collect and store data about our
            interactions with you, your engagement with marketing
            events or campaign activities. Additionally, when you
            start the application process through the online
            application form, we collect the information that you
            include in your responses to the application questions.{' '}
          </p>
        </span>
        <br />
        <span>
          <b>Other correspondence, call recordings, online chat:</b>
          <p>
            We collect personal information from you when you
            correspond with us (for example, if you contact us by
            e-mail, telephone, contact form or online chat).{' '}
            <b className="font-semibold">
              Please note that phone calls, online chat or other
              correspondence will on occasion be recorded for
              security, evidence, training, quality control, analysis
              and development purposes.{' '}
            </b>
          </p>
        </span>
        <br />
        <span>
          <b>Social media platforms data:</b>
          <p>
            If you choose to interact with us via a social media
            platform or other third party service, we will collect the
            information you have provided to us through that platform.
            In addition, you may grant us access to certain data from
            your social media profiles for social log-in purposes.
          </p>
        </span>
        <br />
        <span>
          <b>Information we collect from third parties about you:</b>
          <p>
            We also may collect any of the above information about you
            from third parties, including social media platforms,
            advertising and marketing partners, analytics providers,
            and third parties that provide technical or strategic data
            services to us and we may collate such information with
            other information that we have. We may also collect
            personal information about you from publicly-available
            sources.{' '}
          </p>
        </span>
        <br />
        <span>
          <b>Sensitive or special categories of data:</b>
          <p>
            We may ask you to provide sensitive or special categories
            of data (for example, allergens or accessibility
            requirements for events), in which case we will provide
            you with enhanced privacy information and ask for your
            explicit consent at the time of our request. Otherwise, if
            you provide such data to us without us asking you for it,
            we will take the fact you have provided such data as your
            explicit consent for us to process it.{' '}
          </p>
        </span>
        <br />
        <br />
        <h3 className="text-lg font-medium mb-2">
          Purposes of processing
        </h3>
        <p>
          We may process your personal information for the purposes
          listed below:{' '}
        </p>
        <br />
        <span>
          <b>Service-related processing:</b>
          <p>
            We process your personal information for service-related
            purposes, including providing you information about our
            services, announcements and administrative messages,
            confirmation of, or changes to, your registration for an
            event, as well as to notify you about changes to our terms
            or privacy policy.{' '}
          </p>
        </span>
        <br />
        <span>
          <b>
            Marketing-related communications and digital advertising:{' '}
          </b>
          <p>
            We process your personal information for marketing-related
            purposes, including sending you marketing communications
            (including by telephone, e-mail, post and electronic or
            text messages, including WhatsApp or other one-to-one
            communications, and delivering personalised messages or
            advertising on social media or other digital platforms)
            relating to us and our services.
          </p>
        </span>
        <br />
        <span>
          <b>Applications & records:</b>
          <p>
            We process your personal information for the purposes of
            managing, processing, and responding to your application
            and for our records.
          </p>
        </span>
        <br />
        <span>
          <b>Enquiries:</b>
          <p>
            We process your personal information for the purposes of
            dealing with your enquiries and requests, and offering
            client services to you.
          </p>
        </span>
        <br />
        <span>
          <b>Events:</b>
          <p>
            We process your personal information for the purposes of
            organising and running events, including registering you
            as an attendee or reporting or logging a health and safety
            incident that you may have suffered or assisted with. We
            may also take photographs or make video or voice
            recordings of you at one of our events for promotional
            purposes.
          </p>
        </span>
        <br />
        <span>
          <b>
            Market research, analysis of feedback and client
            engagement:
          </b>
          <p>
            We process your personal information for the purposes of
            conducting market research (including surveys) and
            analysis and improve consumer engagement as a result of
            client feedback, comments, and general use of our Platform
            and services so that we can continuously improve.
          </p>
        </span>
        <br />
        <span>
          <b>Platform support, maintenance and security:</b>
          <p>
            We process your personal information in connection with
            administering and protecting our business and the Platform
            (including troubleshooting, dealing with error messages,
            data analysis, testing, system maintenance, support,
            reporting and hosting of data).
          </p>
        </span>
        <br />
        <span>
          <b>Business administration and legal compliance:</b>
          <p>
            We process your personal information for the purposes of
            the administration of our business or to comply with our
            legal obligations.
          </p>
        </span>
        <br />
        <span>
          <b>
            Enable a corporate transaction such as a merger or
            acquisition:
          </b>
          <p>
            We process your personal information for the purposes of
            any merger or acquisition activity and we will disclose
            your personal information to any third party that
            purchases, or to which we transfer, all or substantially
            all of our assets and business.
          </p>
        </span>
        <br />
        <br />
        <h3 className="text-lg font-medium mb-2">Material Changes</h3>
        <p>
          Where we materially change the way in which we process your
          personal information, or if we plan to use your personal
          information for a new purpose not set out in the section
          above, we will take the appropriate measures required under
          applicable law, such as to notify you by e-mail or other
          suitable means.{' '}
        </p>
        <br />
        <br />
        <br />
        <h2 className="text-xl mb-2 font-medium underline">
          Commitment 2: Protecting Your Personal Information
        </h2>
        <br />
        <h3 className="text-lg font-medium mb-2">
          Protecting your personal information
        </h3>
        <p>
          We want you to feel confident about sharing your personal
          information with us, and we are committed to protecting the
          personal information we collect by implementing leading data
          protection, privacy and security standards. We limit access
          to personal information about you to employees who
          reasonably need access to it, to provide products or
          services to you or in order to do their jobs. We have
          appropriate technical and organisational physical,
          electronic, and procedural safeguards to protect the
          personal information that you provide to us against
          unauthorised or unlawful processing and against accidental
          loss, damage or destruction. However, where we ask you to
          choose a password in order to access certain parts of our
          Platforms, you are responsible for selecting a secure
          password and keeping that password confidential. You should
          choose a password which you do not use on any other site,
          and you should not share it with anyone else.
        </p>
        <br />
        <h3 className="text-lg font-medium mb-2">
          Sharing your personal information
        </h3>
        <p>
          We only share personal information with others when we are
          permitted by law to do so. When we share personal
          information with others, we put contractual arrangements and
          security mechanisms in place to protect the personal
          information shared and to comply with our data protection,
          confidentiality and security standards and obligations. We
          share your personal information with third parties in the
          following circumstances:
        </p>
        <br />
        <span>
          <b>Our affiliated group companies:</b>
          <p>
            We may share your personal information with our affiliated
            group companies for the purposes specified above, where we
            are permitted by law to do so (including where we have a
            lawful basis to do so).
          </p>
        </span>
        <br />
        <span>
          <b>Service providers (including data processors):</b>
          <p>
            We will disclose your personal information to our
            appointed third party service providers (which may process
            your personal information as data processors on our
            behalf), including for the purposes of providing online
            services, fraud prevention, cloud storage and any other
            services required in order for us to use your personal
            information for the purposes specified in this Privacy
            Policy. In certain circumstances, certain fraud-prevention
            service providers consider themselves to be independent
            data controllers.
          </p>
        </span>
        <br />
        <span>
          <b>
            Regulatory, authority and other third party disclosures:{' '}
          </b>
          <p>
            We will disclose your personal information to any law
            enforcement agency, court, police, regulator, government
            authority or any other third party, including a relevant
            financial institution, where we believe this is necessary
            to comply with a legal or regulatory obligation, to
            protect our rights or the rights of any third party, or
            where it is otherwise in the public interest or our
            legitimate interests or those of a third party (for
            example to respond to a request from a third party to
            disclose personal information to investigate an alleged
            crime, to check that we are complying with applicable law
            and regulations, or to establish, exercise or defend legal
            rights).{' '}
          </p>
        </span>
        <br />
        <span>
          <b>Mergers & acquisitions:</b>
          <p>
            We will disclose your personal information to any third
            party that purchases, or to which we transfer, all or
            substantially all of our assets and business. Should such
            a sale or transfer occur, we will use reasonable efforts
            to try to ensure that the entity to which we transfer your
            personal information uses it in a manner that is
            consistent with this Privacy Policy.
          </p>
        </span>
        <br />
        <br />
        <h3 className="text-lg font-medium mb-2">
          Transferring your personal information globally
        </h3>
        <p>
          Personal information we collect may be transferred to,
          stored and processed in any country or territory where one
          or more of our affiliated group companies or any third party
          listed in the section headed “Sharing your personal
          information” above are located or maintain facilities. Some
          of these third parties will use cloud-based storage
          solutions. While other territories may not have the same
          standards of data protection as those within your home
          country, we will continue to protect the personal
          information we transfer in accordance with this Privacy
          Policy.
        </p>
        <br />
        <br />
        <h3 className="text-lg font-medium mb-2">
          Privacy risk assessments
        </h3>
        <p>
          We may periodically conduct risk assessments related to the
          processing of your personal information, especially when
          introducing new technologies or functionalities. In certain
          circumstances, these new technologies or functionalities may
          require us to provide enhanced privacy notices and/or
          consent forms to ensure that we continue to meet our privacy
          commitments towards you.{' '}
        </p>
        <br />
        <br />
        <h3 className="text-lg font-medium mb-2">
          Retaining your personal information{' '}
        </h3>
        <p>
          We keep your personal information only for as long as is
          necessary for our purposes of processing, and in particular
          to protect ourselves in the event of a legal claim (for
          example, information relating to a contract with you will be
          kept for the lifetime of the contract and up to ten years
          after) as well as necessary to comply with statutory
          retention obligations. After this period it will be deleted
          or in some cases anonymised. If you request that we no
          longer send you direct marketing communications or you
          exercise your right to be forgotten, we will keep a record
          of your request and contact details to ensure that your
          request is respected.
        </p>
        <br />
        <br />
        <br />
        <h2 className="text-xl mb-2 font-medium underline">
          Commitment 3: Respecting your rights
        </h2>
        <br />
        <h3 className="text-lg font-medium mb-2">
          California and Texas Privacy Rights
        </h3>
        <br />
        <span>
          <b>Your rights: </b>
          <p>
            Subject to exceptions under applicable law, you may have
            certain choices regarding our use and disclosure of your
            personal information, as described below:{' '}
          </p>
        </span>
        <br />
        <ul className="list-disc">
          <li className="ml-8 mb-2">
            <b>Access:</b>
            <p>
              You have the right to request that we disclose to you
              the personal information we have collected about you,
              including the categories of personal information, the
              categories of sources from which the personal
              information is collected, the business or commercial
              purpose for collecting, selling, or sharing personal
              information, the categories of third parties to whom we
              disclose personal information, and the specific pieces
              of personal information we have collected about you.
              California residents can exercise this right twice in a
              12-month period.{' '}
            </p>
          </li>
          <li className="ml-8 mb-2">
            <b>Correction:</b>
            <p>
              You have the right to request that we correct the
              personal information we maintain about you, if that
              information is inaccurate.
            </p>
          </li>
          <li className="ml-8 mb-2">
            <b>Deletion:</b>
            <p>
              {' '}
              You have the right to request that we delete certain
              personal information we have collected from you.{' '}
            </p>
          </li>
          <li className="ml-8 mb-2">
            <b>Data portability: </b>
            <p>
              For Texas residents, you have the right to request from
              us a copy of the personal information that you have
              provided to us about you in a structured, commonly used,
              machine-readable format.
            </p>
          </li>
        </ul>
        <br />
        <p>
          To exercise your rights on behalf of yourself or another
          individual, please contact us as described in the section
          headed “Contact us” below. If you exercise your rights, we
          may require you to provide certain information to verify
          your identity (such as your name, e-mail address, phone
          number and/or address) or the authority of any third person
          acting on your behalf.{' '}
        </p>
        <br />
        <span>
          <b>No discrimination:</b>
          <p>
            If you choose to exercise any of these rights, you have
            the right to not receive discriminatory treatment by us.
            This section is available in alternative formats upon
            request by contacting us as described in the section
            headed “Contact us” below.
          </p>
          <br />
          <p>
            In addition, California law requires us to identify, for
            the 12-month period prior to the date of this Privacy
            Policy, what information we may have “sold” or “shared”
            about you. For the 12-month period prior to the date of
            this Privacy Policy, we have not sold or shared any
            personal information about you. Additionally, we do not
            collect, use or disclose sensitive personal information,
            as defined in applicable laws. You may appeal our decision
            with respect to a request you have submitted by contacting
            us as described in the section headed “Contact us”
            below. ​{' '}
          </p>
        </span>
        <br />
        <br />
        <h3 className="text-lg font-medium mb-2">Children</h3>
        <p>
          The Platform is not directed at anyone who we know to be
          under 13, and we do not collect any personal information
          from anyone who we know to be a child unless we have
          parental or guardian consent. Children should not use the
          Platform and should not submit any personal information to
          us without parental or guardian consent. We do not sell or
          share the personal information of any consumer, including
          those under the age of 16.{' '}
        </p>
        <br />
        <br />
        <b>Contact Us</b>
        <br />
        <br />
        <a href="mailto:info.na@iosw.com" className="underline">
          info.na@iosw.com
        </a>
        <br />
        <br />
      </div>
    </>
  );
}

export default Privacy;
