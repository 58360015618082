import CloseIcon from './closeIcon';

function Modal({ modalData, setModal }) {
  return (
    <div
      className="modal_bg fixed w-full h-full bg-['rgba(0, 0, 0, 0.753)'] z-[100] flex justify-center items-center"
      onClick={() => setModal(null)}
    >
      <div className="modal">
        <div
          className="close_container"
          onClick={() => setModal(null)}
        >
          <CloseIcon />
        </div>
        <img src={modalData.image} alt={modalData.name} />
        <div className="modal_content">
          <b className="modal_name">{modalData.name}</b>
          <p className="text-xs mb-4">{modalData.role}</p>
          <p className="text-[13px]">{modalData.bio}</p>
        </div>
      </div>
    </div>
  );
}

export default Modal;
