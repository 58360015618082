import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export function ScrollToError() {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    const el = document.querySelector('.form-error');
    (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth' });
  }, [submitting]);
  return null;
}
