import React from 'react';
import { Field } from 'formik';

function Select({ name, options, ...props }) {
  return (
    <Field name={name} as="select" id={name}>
      <option value="">-</option>
      {options.map((option, idx) => (
        <option key={`${idx}_${option}`}>{option}</option>
      ))}
    </Field>
  );
}

export default Select;
