export default function escapeHTML(string) {
  return (
    string.replace(/[&<>"']/g,
    function (match) {
      const escapeMatch = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
      };
      return escapeMatch[match] || '&#39';
    })
  );
}
