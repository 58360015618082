import React from 'react';
import { Helmet } from 'react-helmet';

function Terms() {
  return (
    <>
      <Helmet>
        <title>
          Terms of Use - North American Institute of Swiss Watchmaking
        </title>
        <meta
          name="description"
          content="North American Institute of Swiss Watchmaking (NAIOSW) website, information, and application"
        />
        <meta
          name="keywords"
          content="watchmaking, school, swiss, apply, craftsmanship, learn, horology, WOSTEP, students, education"
        />
      </Helmet>

      <div className="p-16 mt-[160px] max-w-[800px] mx-auto">
        <h1 className="text-3xl !leading-10 font-semibold">
          North American Institute of Swiss Watchmaking Terms of Use
        </h1>
        <br />
        <br />
        <p>Last updated: August 2024</p>
        <br />
        <br />
        <h2 className="text-xl mb-2 font-medium">
          About North American Institute of Swiss Watchmaking
        </h2>
        <p>
          These terms of use (“Terms of Use”) govern your use of the
          North American Institute of Swiss Watchmaking website (the
          “Platform”). In these Terms of Use, we use the term North
          American Institute of Swiss Watchmaking (“NAIOSW”, “we”,
          “us” and “our”) to refer to the North American Institute of
          Swiss Watchmaking at the registered address above and its
          affiliates. North American Institute of Swiss Watchmaking
          has its registered offices at Fort Worth. The Platform is
          owned, operated and edited by Richemont North America, Inc.
          (the “Provider”).{' '}
        </p>
        <br />
        <b className="font-semibold">
          Please read these Terms of Use carefully before using the
          Platform. By using the Platform, you signify your assent and
          agreement to these Terms of Use. If you do not agree to
          these Terms of Use, then you are not authorised to continue
          use of the Platform.{' '}
        </b>
        <br />
        <br />
        <b className="font-semibold">Updates to these Terms of Use</b>
        <br />
        <br />
        <p>
          We may make changes from time to time to these Terms of Use
          so please check back regularly to keep informed of updates.
          The latest version of these Terms of Use will always be
          available on the Platform. Any new version of these Terms of
          Use shall take effect and will govern the use of the
          Platform and your relationship with us immediately upon the
          date of posting. By continuing to use the Platform, you
          agree to be bound by the terms of these updates and
          amendments.{' '}
        </p>
        <br />
        <br />
        <br />
        <h3 className="text-lg font-medium mb-2">
          About our hosting services
        </h3>
        <p>
          Hosting services for our website is provided by the Provider
          in the US.{' '}
        </p>

        <br />
        <h3 className="text-lg font-medium mb-2">
          Our privacy policy{' '}
        </h3>
        <p>
          Our information collection practices on the Platform, such
          as the types of information we collect regarding visitors to
          the Platform and the ways in which we may use that
          information, are governed by the terms of our{' '}
          <a href="/privacy-policy" className="underline">
            Privacy Policy.
          </a>
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">
          Use of materials on the Platform{' '}
        </h3>
        <p>
          We have created the Platform for your personal use to
          provide you information about NAIOSW and its services.
          Unless otherwise stated, you should assume that everything
          that you see or read on the Platform (such as creations,
          products, images, photographs, including any person
          represented in the photographs, illustrations, icons, texts,
          video clips, music, written and other materials){' '}
          <b className="font-semibold">(“NAIOSW Material”)</b> are
          protected by legislation such as copyright, designs and
          trademark legislation and under international treaty
          provisions and national laws worldwide.{' '}
        </p>
        <br />
        <p>
          You are not authorised to sell, reproduce, distribute,
          communicate, modify, display, publicly perform, report or
          otherwise prepare derivative or second hand works based on
          or use any NAIOSW Material in any way for any public or
          commercial purposes. Furthermore, NAIOSW Material may not be
          displayed or communicated on any other platform, in a
          networked computer environment or on any other digital
          platform for any purpose whatsoever. In the event of breach
          of any of these Terms of Use, your permission to use NAIOSW
          Material will automatically terminate and any copies made of
          NAIOSW Material must be immediately destroyed. Any
          unauthorised use of NAIOSW Material may infringe copyright
          laws, trademark laws, the laws of privacy and publicity, and
          communications regulations and statutes.{' '}
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">
          Your submissions and unsolicited communications{' '}
        </h3>
        <p>
          This section concerns communications sent to NAIOSW. It does
          not concern the communication of personal information to
          NAIOSW in relation to enquiries or applications to NAIOSW
          programs; such information is governed by the rules
          stipulated in the{' '}
          <a href="/privacy-policy" className="underline">
            Privacy Policy.
          </a>
        </p>
        <br />
        <p>
          Any unsolicited communication or material that you transmit
          to NAIOSW via the Platform or through social media, by
          electronic mail or otherwise, including, but not limited to,
          any data, questions or answers, comments, suggestions, or
          the like will be treated by us as non-confidential and
          non-proprietary.{' '}
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">
          Limitation of liability{' '}
        </h3>
        <p>
          This section applies to the Platform and not to the services
          of NAIOSW.{' '}
        </p>
        <br />
        <p>
          NAIOSW tries to ensure that the information provided is
          accurate and complete. However, NAIOSW does not warrant or
          represent that NAIOSW Material is accurate, error-free or
          reliable or that use of NAIOSW Material will not infringe
          rights of third parties.{' '}
        </p>
        <br />
        <p>
          We do not warrant that the functional and/or technical
          aspects of the Platform or the NAIOSW Material will be error
          free or that the Platform, NAIOSW Material or the servers
          that make them available are free of viruses or other
          harmful components. If use of the Platform or NAIOSW
          Material results in the need for servicing or replacing
          property, material, equipment, data or other element, NAIOSW
          is not responsible for those costs. Without limiting the
          foregoing, everything on the Platform is provided to you "AS
          IS" AND "AS AVAILABLE" AND, TO THE FULLEST EXTENT PERMITTED
          BY APPLICABLE LAW, WITHOUT WARRANTY OF ANY KIND, EITHER
          EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
          IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY,
          FITNESS FOR A PARTICULAR PURPOSE, REASONABLE CARE AND SKILL,
          OR NON-INFRINGEMENT. NAIOSW makes no warranties about the
          NAIOSW Material, software, text, downloads, graphics, and
          links, or about results to be obtained from using the
          Platform.{' '}
        </p>
        <br />
        <p>
          To the fullest extent permitted by applicable law, NAIOSW
          shall not be liable for any indirect, incidental, special or
          consequential damages of any kind arising out of or in
          connection with the use of information available from the
          Platform or any liability relating to any loss of use,
          interruption of business, lost profits or lost data,
          regardless of the form of action, whether in contract, tort
          (including negligence) or otherwise, even if NAIOSW has been
          advised of the possibility of such damages.
        </p>
        <br />
        <p>
          Please note that in some jurisdictions consumer protection
          laws may not allow certain exclusions or limitation of
          warranties or liabilities, and consequently some of the
          above exclusions and limitations may not apply.{' '}
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">
          Trademark notice{' '}
        </h3>
        <p>
          In general, all trademarks, logos and service marks
          (collectively the “Trademarks”) that appear on the Platform
          are registered, unregistered or otherwise protected NAIOSW
          trademarks or are licensed for use by NAIOSW by third
          parties. Other trademarks are proprietary marks and are
          registered to their respective owners. Nothing contained on
          the Platform should be construed as granting, by implication
          or otherwise, any licence or right to use any trademark
          without NAIOSW’s prior written permission or that of such
          third party who owns the trademark. Misuse of any trademark
          displayed on the Platform, or any other content on the
          Platform, except as provided herein, is strictly prohibited.{' '}
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">Copyright notice</h3>
        <p>
          All content (including NAIOSW Materials) on the Platform are
          either Copyright © NAIOSW or are licensed for use by NAIOSW.
          All rights are reserved. Please refer to the section above
          on Use of Materials on the Platform.{' '}
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">Links & linking</h3>
        <p>
          The Platform includes a link to the careers site for
          Richemont, which is affiliated with NAIOSW. Note that the
          Richemont website has its own privacy policy that you should
          review if you elect to click on that link. The Platform may
          also contain links to other platforms operated by third
          parties not affiliated to NAIOSW. The inclusion of any link
          to such third party sites does not imply endorsement of
          those sites by NAIOSW. NAIOSW has not reviewed all of the
          content contained in the linked sites and is not responsible
          for the content or accuracy of any off-site pages or any
          other sites linked to the Platform. If you choose to click
          through any link to off-site pages or third party sites then
          this is at your own risk. NAIOSW does not authorise linking
          to its Platform from a third party platform without its
          express prior written authorisation.
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">
          Termination and suspension{' '}
        </h3>
        <p>
          You agree that NAIOSW may terminate or suspend your access
          to and use of the Platform if it reasonably believes that
          you have violated or acted inconsistently with the letter or
          spirit of these Terms of Use, or violated the rights of
          NAIOSW, its affiliated companies or any third party, with or
          without notice to you. You agree that NAIOSW may modify or
          discontinue your access to the Platform, with or without
          notice to you. You agree that NAIOSW will not be liable to
          you or any third party as a result of such modification or
          discontinuation. The provisions entitled "Limitation of
          liability" and "General provisions" will survive termination
          of these Terms of Use.{' '}
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">
          General provisions{' '}
        </h3>
        <p>
          Unless otherwise specified, the information and NAIOSW
          Materials presented on the Platform are presented solely for
          the purpose of promoting NAIOSW’s services.{' '}
        </p>
        <br />
        <p>
          If any provision, or part of a provision, of these Terms of
          Use is found to be illegal, invalid or unenforceable, that
          provision or part-provision shall be deemed not to form part
          of these Terms of Use, and the legality, validity or
          enforceability of the remainder of the provisions of these
          Terms of Use shall not be affected, unless otherwise
          required by operation of applicable law.{' '}
        </p>
        <br />
        <p>
          These Terms of Use constitute the entire agreement between
          you and us in relation to the use of the Platform, and
          replace and extinguish all prior agreements, draft
          agreements, arrangements, undertakings, or collateral
          contracts of any nature made by the parties, whether oral or
          written, in relation to such subject matter.{' '}
        </p>
        <br />
        <p>
          The waiver by NAIOSW of a breach of any provision of these
          Terms of Use will not operate to be interpreted as a waiver
          of any other or subsequent breach.{' '}
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">
          Applicable law and jurisdiction{' '}
        </h3>
        <p>
          These Terms of Use shall be governed by and construed in
          accordance with the laws of the State of Texas, without
          reference to conflict of laws provisions. Any dispute,
          controversy or claim arising out of or in relation to the
          Terms of Use, including the validity, invalidity, breach or
          termination thereof, shall be adjudicated or arbitrated in
          accordance with said Terms of Use.
        </p>
        <br />

        <h3 className="text-lg font-medium mb-2">Contact us</h3>
        <p>
          If you have any questions or comments about these Terms of
          Use, or matters generally, please contact us at the address
          provided below.
        </p>
        <br />
        <a href="mailto:info.na@iosw.com" className="underline">
          info.na@iosw.com
        </a>
        <br />
        <br />
      </div>
    </>
  );
}

export default Terms;
